import React from "react";
import { graphql } from "gatsby";
import {Helmet} from "react-helmet"
import Cover from "../components/project/cover";
import ImagesList from "../components/project/imagesList";
import Header from "../components/uielements/header/header";
import Footer from "../components/footer/footer";
import '../components/project/project.scss';

export default function Project({data}) {
    const { markdownRemark } = data
    const { frontmatter } = markdownRemark
    const { title, images, cover, description } = frontmatter
    return (
        <>
            <Helmet>
              <title>{title}</title>
              <meta name="description" content={description}/>
            </Helmet>
            <Header />
            <Cover
                title={title}
                cover={cover}
            />
            <ImagesList 
                images={images}
            />
            <Footer />
        </>
    )
}
export const pageQuery = graphql`
    query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        cover
        images
        description
      }
    }
  }
`