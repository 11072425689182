import React, { useState } from "react";
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";

export default function ImagesList(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    const photos = props.images.map(image => {
        return {
            src: image,
            width: 0,
            height: 0
        }
    })

    const ImageRender = ({
        index,
        photo,
        margin,
        direction,
        top,
        left,
        selected
      }) => {
        return (
            <div className="image-render-container" 
            onClick={() => openLightbox(index)}
            >
                <div className="image-render" style={{
                    
                    backgroundImage: `url(${photo.src})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>

                    </div>
            </div>
        )
    }

    return (
        <div className="project__images-list">
            <Gallery
                photos={photos}
                renderImage={ImageRender}
                onClick={openLightbox}
              />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    )
}